<template>
  <div class="plan-card" @click.stop="handleClick">
    <div class="cover">
      <div :class="`free-type free-type-${detail.plan.freeType}`">
        <template v-if="detail.plan.freeType===1">免费方案</template>
        <template v-else-if="detail.plan.freeType===2">会员免费</template>
        <template v-else-if="detail.plan.freeType===3">会员折扣</template>
      </div>
      <div class="special-tags">
        <div class="special-tag" v-if="detail.plan.brokerTag"><span>券商</span></div>
        <div class="special-tag" v-if="detail.plan.foreignTag"><span>外文</span></div>
        <div class="special-tag special-tag-pretty" v-if="detail.plan.yearTopTag">
            <span>
              <img src="https://cdn.001ppt.cn/pc2/static/planCard/year-top-tag.svg" alt="">
              <span style="transform: translateX(-2px)">年度TOP</span>
            </span>
        </div>
        <div class="special-tag special-tag-pretty"
             v-if="detail.statisticTotal && detail.statisticTotal.unlockNum >= 100">
            <span>
              <img src="https://cdn.001ppt.cn/pc2/static/planCard/down-100-times-tag.svg" alt="">
              <span style="transform: translateX(-2px)">百次下载</span>
            </span>
        </div>
      </div>


      <van-image width="100%"
                 height="100%"
                 :src="detail.plan.cover"
                 use-loading-slot
      >
        <div slot="loading" class="skeleton-block" style="height: 100%;width: 100%"></div>
      </van-image>

      <div class="cover-info">
        <div class="dynamic">
          <div class="dynamic-item opacity-btn" @click.stop="toggleCollect">
            <template>
              <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/star-active.svg"
                   style="height: 12px;width: 12px;margin-right: 4px" v-if="detail.hasCollect" alt="">
              <!--              <i class="iconfont2 icon-star" v-if="!detail.hasCollect"></i>-->
              <i class="iconfont2 icon-star" v-else></i>
            </template>
            <div class="dynamic-item-value" v-if="detail.plan.collectTimes">{{ detail.plan.collectTimes }}</div>
          </div>
        </div>
        <div class="dynamic">
          <div class="dynamic-item opacity-btn" style="margin-right: 4px">
            {{ fileExtFilter(detail.plan.fileExt) }}
          </div>
          <div class="dynamic-item opacity-btn">
            {{ detail.plan.pageTotal }}页
          </div>
        </div>
      </div>
    </div>

    <div class="plan-info">
      <div class="plan-title-line">
        <img class="recommended-icon" v-if="detail.plan.isRecommended"
             src="https://cdn.001ppt.cn/pc2/static/imgs/svg/high-quality2.svg" alt="">
        <div class="plan-title">{{ detail.plan.planTitle }}</div>
      </div>
      <div class="plan-tag-line">
        <div class="plan-tag" v-for="(item,index) in setTag(detail.plan.planTagName)" v-if="index<3" :key="index"
             @click.stop="searchByTag(item)">
          {{ item }}
        </div>
      </div>
    </div>


    <div class="plan-creator">
      <div class="plan-creator-block" @click.stop="goCreator">
        <img class="avatar" :src="formatAvatar(detail.creator.avatar)">
        <div class="nickName">{{ detail.creator.nickName }}</div>
        <img class="vip-icon" @click.stop="goVip" :src="getVipIcon(detail.creator.vipTypeName)"
             v-if="getVipIcon(detail.creator.vipTypeName)">
      </div>
      <div class="plan-creator-block" @click.stop="goUserLevel">
        <user-level :level="detail.creator.userLevel"/>
      </div>
    </div>
  </div>
</template>

<script>
import UserLevel from "./userLevel";
import {planV2Api} from "../api/v2/planV2Api";
import {sleep} from "../config/util";

export default {
  components: {UserLevel},
  props: ['detail', 'stopTagClick'],

  methods: {
    //根据tag搜索
    searchByTag(tag) {
      if (this.stopTagClick) {
        return this.$emit('clickTag', tag)
      }
      let url = '';
      if (tag) {
        url = `${window.location.origin}/home?keyword=${tag}`;
      } else {
        if (this.keyword) {
          url = `${window.location.origin}/home?keyword=${this.keyword}`;
        } else {
          url = `${window.location.origin}/home`;
        }
      }
      window.location.replace(url);
    },


    goVip() {
      let vipRightId = ''
      switch (this.detail.creator.vipTypeName) {
        case '超级VIP会员':
          vipRightId = 4;
          break
        case '入门会员':
          vipRightId = 2;
          break
        case '试用会员':
          vipRightId = 1;
          break
        case '俱乐部会员':
          vipRightId = 5;
          break
      }
      this.$router.push('/member?id=' + vipRightId)
    },


    goUserLevel() {
      this.$router.push('/userLevel')
    },


    goCreator() {
      this.$router.push( `/otherPerson/${this.detail.creator.id}`)
    },


    handleClick(e) {
      const detail = this.detail
      if (!this.search) {
        return this.$emit('handle-click', detail);
      }
      this.$emit('handle-click', detail);
    },


    async toggleCollect() {
      if (!this.hasLogin) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      const detail = this.detail
      await planV2Api.toggleCollect({planId: detail.id || detail.plan.id})
      const currHasCollect = detail.hasCollect

      let delta = 0
      if (currHasCollect) {
        detail.hasCollect = false
        delta = -1
        await sleep(500)
        this.$myNotify.show({
          type: 'info',
          text: '取消收藏成功',
        })
      } else {
        detail.hasCollect = true
        delta = 1
        await sleep(500)
        this.$myNotify.show({
          type: 'success',
          text: '收藏成功',
          rightText: '去看看',
          onClick: () => {
            this.$router.push('/collection?refresh')
          }
        })

      }
      if (detail.collectAmount) {
        detail.collectAmount += delta
        detail.collectAmount = Math.max(detail.collectAmount, 0)
      } else {
        detail.plan.collectTimes += delta
        detail.plan.collectTimes = Math.max(detail.plan.collectTimes, 0)
      }

      this.$emit('update:detail', detail)
    },

  }
}
</script>

<style scoped lang="less">
.plan-card {
  width: calc(100vw - (28px * 2));
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.plan-card .cover {
  width: calc(100vw - (28px * 2));
  height: calc((100vw - (28px * 2)) * 0.56);
  position: relative;
  overflow: hidden;
}

.plan-card .cover .free-type {
  position: absolute;
  left: 8px;
  top: 8px;
  padding: 2px 8px;
  border-radius: 100px;
  z-index: 1;
  font-size: 12px;
}


.plan-card .cover .free-type-1 {
  background: grey;
  color: #fff;
}

.plan-card .cover .free-type-2 {
  background: #fff1c6;
  color: #444;
}

.plan-card .cover .free-type-3 {
  background: #151D36;
  color: #fff;
}

.plan-card .cover .cover-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.plan-card .cover .dynamic {
  display: flex;
  align-items: center;
}

.plan-card .opacity-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  padding: 2px 8px;
}

.plan-card .opacity-btn::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #151d36;
  opacity: .3;
  border-radius: 100px;
  z-index: -1;
}

.plan-card .dynamic-item-value {
  transform: translateY(1px);
  padding-left: 1px;
}

.plan-card .plan-info {
  background: #fff;
  z-index: 1;
}


.plan-card .plan-info .plan-title-line {
  z-index: 1;
  padding: 8px 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.plan-card .plan-title-line .recommended-icon {
  height: calc(15px * 150 / 198);
  width: 15px;
  margin-right: 2px;
  flex-shrink: 0;
}

.plan-card .plan-info .plan-title {
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100vw - 56px - 16px - 24px);
  font-weight: bolder;
}

.plan-card .plan-tag-line {
  display: flex;
  align-items: center;
  padding: 0 8px;
  max-width: calc(100vw - 56px - 16px);
}

.plan-card .plan-tag-line .plan-tag {
  padding: 2px 8px;
  border-radius: 100px;
  font-size: 12px;
  background-color: var(--grey-bg);
  color: var(--grey-color);
  margin-right: 4px;
  max-width: calc((100vw - 56px - 16px - 8px) / 3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.plan-card .plan-creator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  margin-top: 10px;
  position: relative;
}

.plan-card .plan-creator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 8px;
  right: 8px;
  height: 1px;
  background: #e9e9e9;
  transform: scaleY(.5);
}

.plan-card .plan-creator-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.plan-card .plan-creator .avatar {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #e9e9e9;
  margin-right: 8px;
  flex-shrink: 0;
}

.plan-card .plan-creator .nickName {
  font-size: 12px;
  flex-shrink: 0;
}

.plan-card .plan-creator .vip-icon {
  height: 16px;
  width: 16px;
  background: #e9e9e9;
  border-radius: 100px;
  margin-left: 4px;
}

.special-tags {
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 12px;
  z-index: 1;
}


.special-tag {
  margin-left: 2px;
  height: 16px;
  padding: 0 4px;
  border-radius: 100px;
  position: relative;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.special-tag span {
  display: flex;
  font-size: 12px;
  transform: scale(.8);
  align-items: center;
  color: #fff;
}

.special-tag::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #151d36;
  opacity: .3;
  border-radius: 100px;
  z-index: -1;
}

.special-tag-pretty {
  background: #ee4a50;
  padding: 0 4px;
  color: #ffe19f;
}

.special-tag-pretty span{
  color: #ffe19f;
}

.special-tag-pretty::before {
  display: none;
}


.special-tag-pretty img {
  height: 20px;
  width: 20px;
}
</style>
